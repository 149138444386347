<template>
  <div>
    <section>
        <button
            class="uk-button uk-button-default uk-border-rounded uk-margin-right"
            style="background: #0ABAB5; border-color: #0ABAB5; color: #ffffff"
            @click="saveData"
        >
            Save
        </button>
        <button
            class="uk-button uk-button-default uk-border-rounded uk-margin-right"
            style="border-color: #0ABAB5; color: #0ABAB5"
            @click="$emit('action', false)"
        >
            Cancel
        </button>
    </section>
    <section class="uk-margin">
      <div
        class="uk-grid-collapse"
        style="padding: .2rem 0"
        uk-grid
      >
        <div class="uk-width-1-2 uk-margin-auto-vertical">Nama Ibu Kandung</div>
        
        <div class="uk-width-1-2">
          <div class="uk-grid-collapse" uk-grid>
            <div class="uk-width-expand">
              <input
                v-model="form.mother_name"
                class="uk-input"
                type="text"
              >
            </div>
          </div>
        </div>
      </div>
      <section 
        v-for="(contacts, cKey) in 3"
        :key="`c-${cKey}`"
      >
        <div
          class="uk-grid-collapse"
          style="padding: .2rem 0"
          uk-grid
          v-for="(detail, dKey) in details"
          :key="`d-${dKey}`"
        >
          <div class="uk-width-1-2 uk-margin-auto-vertical">{{ `${detail.label} ${cKey + 1}` }}</div>
          
          <div class="uk-width-1-2">
            <div class="uk-grid-collapse" uk-grid>
              <div class="uk-width-expand">
                <input
                  v-model="form.contacts[cKey][detail.value]"
                  class="uk-input"
                  type="text"
                >
              </div>
            </div>
            <div v-if="errorsD[cKey] && errorsD[cKey][detail.value]" class="uk-text-danger">{{ errorsD[cKey][detail.value] }}</div>
          </div>
        </div>
      </section>
    </section>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { notificationSuccess } from '@/utils/notification';

export default {
    props: {
        data: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            isLoading: false,
            contacts: [],
            details: [
                { label: 'Nama Kontak Darurat', value: 'name' },
                { label: 'Hubungan Kontak Darurat', value: 'relationship' },
                { label: 'Nomor Telefon Kontak Darurat', value: 'phone_number' },
            ],
            form: { ...this.$props.data },
            errorsD: [{}],
        };
    },

    mounted() {
        this.getDetailContacts();
    },

    methods: {
        ...mapActions({
            patchEmergencyContact: 'mitra/patchEmergencyContact',
        }),

        // create default emergency contact if contact data less than 3
        getDetailContacts() {
            const totalContact = this.form.contacts.length;
            const maxContact = 3 - totalContact;

            if (maxContact > 0) {
                let contact = [];
                for (let index = 0; index < maxContact; index++) {
                    contact[index] = {
                        name: null,
                        relationship: null,
                        phone_number: null,
                    };
                }

                this.form.contacts = [ ...this.form.contacts, ...contact ];
            }
        },

        async saveData() {
            this.isLoading = true;

            if (!this.validateData()) {
                this.isLoading = false;
                return false;
            }

            const nForm = {
                "detail": {
                    "emergency_contact": {
                        "mother_name": !this.form.mother_name || this.form.mother_name === ''
                            ? null : this.form.mother_name,
                        "contacts": this.contacts
                    }
                }
            };

            await this.patchEmergencyContact({ user_id: this.$route.params.mitra_id, payload: nForm });
            notificationSuccess('Update saved!');

            this.isLoading = false;
            this.$emit('action', false);
        },

        validateData() {
            this.errorsD = [{}];
            let isValidated = true;
            this.contacts = [];
            
            this.form.contacts.forEach((data, dKey) => {
                if (data.name || data.relationship || data.phone_number) {
                    if (!data.name) {
                        if (!this.errorsD[dKey]) {
                            this.errorsD[dKey] = { name: "nama harus diisi" };
                        } else {
                            this.errorsD[dKey]["name"] = "nama harus diisi";
                        }
    
                        isValidated = false;
                    }
    
                    if (!data.relationship) {
                        if (!this.errorsD[dKey]) {
                            this.errorsD[dKey] = { relationship: "hubungan harus diisi" };
                        } else {
                            this.errorsD[dKey]["relationship"] = "hubungan harus diisi";
                        }
    
                        isValidated = false;
                    }
    
                    if (!data.phone_number) {
                        if (!this.errorsD[dKey]) {
                            this.errorsD[dKey] = { phone_number: "nomor telefon harus diisi" };
                        } else {
                            this.errorsD[dKey]["phone_number"] = "nomor telefon harus diisi";
                        }
    
                        isValidated = false;
                    }

                    // parsing validated data to new contact format
                    this.contacts.push(data);
                }
            });

            return isValidated ? true : false;
        },
    }
};
</script>

<style scoped>
  .avatar {
    width: 100px;
    height: 100px;
    border: 0;
  }
</style>
