<template>
  <div>
    <section>
        <button
            v-if="$can('EDIT', 'worker-mitra')"
            class="uk-button uk-button-default uk-border-rounded uk-margin-right"
            style="background: #0ABAB5; border-color: #0ABAB5; color: #ffffff"
            @click="$emit('action', true)"
        >
            Edit
        </button>
    </section>
    <section class="uk-margin">
      <div
        class="uk-grid-collapse"
        style="padding: .2rem 0"
        uk-grid
      >
        <div class="uk-width-1-2">Nama Ibu Kandung</div>
        
        <div class="uk-width-1-2">
          <div class="uk-grid-collapse" uk-grid>
            <div class="uk-width-auto">:&nbsp;</div>
            <div class="uk-width-expand">{{ data.mother_name }}</div>
          </div>
        </div>
      </div>
      <section 
        v-for="(contacts, cKey) in 3"
        :key="`c-${cKey}`"
      >
        <div
          class="uk-grid-collapse"
          style="padding: .2rem 0"
          uk-grid
          v-for="(detail, dKey) in details"
          :key="`d-${dKey}`"
        >
          <div class="uk-width-1-2">{{ `${detail.label} ${cKey + 1}` }}</div>
          
          <div class="uk-width-1-2">
            <div class="uk-grid-collapse" uk-grid>
              <div class="uk-width-auto">:&nbsp;</div>
              <div class="uk-width-expand">{{ data.contacts[cKey] ? data.contacts[cKey][detail.value] : "-" }}</div>
            </div>
          </div>
        </div>
      </section>
    </section>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    data() {
        return {
            isLoading: false,
            details: [
                { label: 'Nama Kontak Darurat', value: 'name' },
                { label: 'Hubungan Kontak Darurat', value: 'relationship' },
                { label: 'Nomor Telefon Kontak Darurat', value: 'phone_number' },
            ],
            data: null,
        };
    },

    async mounted() {
        this.getMitraEmergencyContact();
    },

    methods: {
        ...mapActions({
            getEmergencyContact: 'mitra/getEmergencyContact',
        }),

        async getMitraEmergencyContact() {
            this.isLoading = true;
            const data = await this.getEmergencyContact(this.$route.params.mitra_id);

            if (data.result) {
                this.data = data.result.detail.emergency_contact;
                this.$emit("passData", this.data);
            }

            this.isLoading = false;
        }
    }
};
</script>

<style scoped>
  .avatar {
    width: 100px;
    height: 100px;
    border: 0;
  }
</style>
